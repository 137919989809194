@font-face {
    font-family: 'Lato-Italic';
    font-style: italic;
    font-weight: 400;
    src: local('Lato Italic'), local('Lato-Italic'),
    url('./fonts/lato-italic.woff2') format('woff2');
}
@font-face {
    font-family: 'Lato-BoldItalic';
    font-style: italic;
    font-weight: 700;
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
    url('./fonts/lato-bold-italic.woff2') format('woff2');
}
@font-face {
    font-family: 'Lato-Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'),
    url('./fonts/lato-regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Lato-Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'),
    url('./fonts/lato-bold.woff2') format('woff2');
}
@font-face {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('./fonts/montserrat-regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('./fonts/montserrat-bold.woff2') format('woff2');
}
