@import url("font.css");

body {
  font-family: 'Montserrat-Bold';
}

.Main {
  font-family: 'Montserrat-Bold';
  background-color:#69c7ee;
  color: #3343a8;
  height: 100vh;
  display: flexbox;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.Main h1 {
  color: #fff;
}

.Main h4 {
  margin-top: .5em;
  color: #fff;
}

.hr-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.hr-line {
  width: 30%;
  position: relative;
  margin: 15px;
  border-bottom: 1px solid #fff;
}
.hr-icon {
  position: relative;
  top: 3px;
  font-size: 2em;
  color: #fff;
}

.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
 /* background-color: #69c7ee; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.avatar {
 background-color: #799aff;
 vertical-align: middle;
 width: 20vw;
}
